.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  .modal {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 60%;
    max-width: 600px;
    overflow-y: auto;
    position: relative; /* 상대 위치 설정 */
  }
  
  .modal h2 {
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 3rem;
  }
  
  .modal p {
    // margin-bottom: 20px;
    font-size: 18px;
    margin-bottom: 3rem;
  }
  
  .modal button {
    position: absolute; /* 절대 위치 설정 */
    bottom: 20px; /* 아래에서 20px 떨어진 위치에 배치 */
    right: 20px; /* 오른쪽에서 20px 떨어진 위치에 배치 */
    padding: 12px 24px;
    font-size: 16px;
    background-color: #5fc4b2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal button:hover {
    background-color: #2aac94;
  }