.note-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  
    .note-grid {
      display: grid;
      grid-template-columns: repeat(7, 50px);
      gap: 1px;

      @media (max-width: 768px) {
        &.note {
          margin-right: 1rem;
          margin-left: -2rem;
        }
      }
    }
  
    .note-row {
      display: contents;
    }
  
    .note-row .note-cell:first-child {
      border: none;
    }

    .note-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 30px;
      border: 1px solid #555555;
      font-size: 18px;
      font-weight: bold;
      color: #333;
      position: relative;

      >.position-mark {
        background-color: rgb(117, 113, 113);
        width: 10px;
        height: 10px;
        border: 1px solid rgb(117, 113, 113);
        border-radius: 50%;
        font-size: 13px;
      }

      >.target {
        background-color: rgb(255, 255, 255);
        width: 20px;
        height: 20px;
        border: 1px solid #000000;
        border-radius: 50%;
        font-size: 13px;
        position: absolute;
        top: -13px;
        // left: -35px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        
        &.target-x {
            border: none;
            font-size: 17px;
            top: -15px;
            color: rgb(231, 83, 83);
            // left: -26px;
        }

        &.target-zero {
            top: -12px;
            // left: -30px;
        }

        &.target-note {
            background-color: red;
        }
      }
      
      &.note-cell-first {
        width: 48px;
        border-left: 3px solid #555555;
      }
    }


    // .note-flat {
    .note-grid .note-row:last-child {
        >.note-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 30px;
            font-size: 18px;
            font-weight: bold;
            color: #333;
            border: none;
        }
    }
}

.chord-type {
  margin-bottom: 2rem;
  font-size: 16px; /* 적당한 크기의 글씨 */
  font-weight: bold;
  color: #4CAF50; /* 상쾌한 녹색 톤으로 변경 */
  padding: 6px 40px;
  border: 2px solid #4CAF50;
  border-radius: 20px; /* 둥근 모서리 */
  background-color: #e8f5e9; /* 밝은 녹색 배경 */
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 */
}


.play-stroke-btn {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* 버튼 크기 줄임 */
  height: 40px; /* 버튼 크기 줄임 */
  border: none;
  border-radius: 50%; /* 원형 버튼 */
  background-color: #2aac94;
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 약간의 음영 효과 */
  transition: background-color 0.3s, transform 0.2s;
}

.play-stroke-btn:hover {
  background-color: #1e8c74;
  transform: scale(1.1); /* 호버 시 약간 커지는 효과 */
}

.play-stroke-btn:active {
  transform: scale(1.05); /* 클릭 시 살짝 작아지는 효과 */
}

.play-stroke-btn svg {
  font-size: 16px; /* 아이콘 크기 줄임 */
}
