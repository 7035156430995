.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-height: calc(100vh - 10vh); /* 화면의 대부분을 차지하도록 설정 (푸터 높이 조정) */
    >div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 90vh;
    }
  }
  
  .contact-container h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    color: #333;
  }
  
  .contact-container p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: #555;
  }
  
  .kakao-link {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #ffeb00;
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .kakao-link:hover {
    background-color: #f7e000;
  }
  