.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  position: relative; /* Ensure the elements have a positioning context */
  z-index: 1; /* Higher z-index to be on top of falling notes */
}

.typing-text {
  margin-bottom: 2rem;
  font-size: 24px;
  position: relative;
  z-index: 2; /* Ensure it is above the falling notes */
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2; /* Ensure it is above the falling notes */
}

.input-container input {
  width: 19rem;
  margin-bottom: 2rem;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  z-index: 2; /* Ensure it is above the falling notes */
}

.welcome-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #5fc4b2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  z-index: 2; /* Ensure it is above the falling notes */
}

.welcome-button:hover {
  background-color: #2aac94;
}

.go-back-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: transparent; /* Background transparent */
  color: #007bff; /* Link color */
  border: none;
  cursor: pointer;
  position: absolute; /* Absolute positioning */
  top: 20px; /* Top margin */
  left: 20px; /* Left margin */
  z-index: 2; /* Ensure it is above the falling notes */
}

.go-back-button:hover {
  text-decoration: underline; /* Underline on hover */
}
