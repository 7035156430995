.main {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  margin-top: 65px;
  height: 53rem;
}

.hero {
  // background-image: url('/img/background-image.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 10rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  height: 18rem;

  h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  p {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .cta-button {
    background-color: #2aac94;
    color: white;
    padding: 15px 30px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #1e8c74;
    }
  }
}

.features {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  gap: 1rem;

  .feature {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 30%;
    cursor: pointer; /* 클릭 가능하도록 커서 변경 */
    transition: transform 0.3s, box-shadow 0.3s; /* 부드러운 전환 효과 */

    &:hover {
      transform: translateY(-10px); /* 마우스 오버 시 위로 살짝 이동 */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 마우스 오버 시 그림자 강화 */
    }

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #555;
    }
  }
}

@media (max-width: 768px) {
  .main {
    height: 50rem;
  }

  .hero {
    height: 1rem;
  }

  .features {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    gap: 0;

    .feature {
      width: 80%;
      margin-bottom: 20px;
    }
  }
}
