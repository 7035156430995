.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*아래 폰트*/
/* @font-face {
  font-family: "NotoSansKR-SemiBold";
  font-weight: 50;
  src: url("./fonts/NotoSansKR-SemiBold(ttf).ttf") format("truetype");
}

@font-face {
  font-family: "MangoDdobak-L";
  src: url("./fonts/MangoDdobak-L(ttf).ttf");
}

@font-face {
  font-family: "MangoDdobak-R";
  src: url("./fonts/MangoDdobak-R(ttf).ttf");
} */
@import url('//fonts.googleapis.com/earlyaccess/notosanskr.css');

/* @font-face {
  font-family: "NotoSansKR-Black";
  src: url("./fonts/NotoSansKR-Black.ttf");
}
@font-face {
  font-family: "NotoSansKR-Bold";
  src: url("./fonts/NotoSansKR-Bold.ttf");
}
@font-face {
  font-family: "NotoSansKR-ExtraBold";
  src: url("./fonts/NotoSansKR-ExtraBold.ttf");
}
@font-face {
  font-family: "NotoSansKR-ExtraLight";
  src: url("./fonts/NotoSansKR-ExtraLight.ttf");
}
@font-face {
  font-family: "NotoSansKR-Light";
  src: url("./fonts/NotoSansKR-Light.ttf");
}
@font-face {
  font-family: "NotoSansKR-Medium";
  src: url("./fonts/NotoSansKR-Medium.ttf");
}
@font-face {
  font-family: "NotoSansKR-Regular";
  src: url("./fonts/NotoSansKR-Regular.ttf");
}
@font-face {
  font-family: "NotoSansKR-SemiBold";
  src: url("./fonts/NotoSansKR-SemiBold.ttf");
}
@font-face {
  font-family: "NotoSansKR-Thin";
  src: url("./fonts/NotoSansKR-Thin.ttf");
} */
body {
  font-family: 'Noto Sans KR', sans-serif;
}

/* .body {
  font-family: "NotoSansKR-Regular";
}

button {
  font-family: "NotoSansKR-Bold";
} */