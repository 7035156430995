.footer {
  color: #ababab;
  padding: 20px 0;
  width: 100%;
  background-color: #ffffff;
  position: relative; // 기본 위치

  @media (max-width: 768px) {
    z-index: 1000; // 메뉴 위에 표시되도록 z-index 설정
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-left {
  font-size: 14px;
}

.footer-right {
  display: flex;
  gap: 15px;
}

.footer-right a {
  color: #ababab;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;

  &:hover {
    color: #5fc4b2;
  }
}
