.header {
  color: #494747;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  font-weight: 700;

  .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .header-top {
    position: absolute;
    left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #2aac94;
    font-size: 24px;

    .logo {
      height: 40px;
      margin-right: 10px;
    }

    @media (max-width: 768px) {
      justify-content: center;
      width: 100%;
      font-size: 20px;
      position: relative;
    }
  }

  .menu-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    &__nav {
      display: flex;
      gap: 0.25rem;
      color: #494747;
      font-size: 16px;

      @media (max-width: 768px) {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
      }
    }

    &__nav-item {
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 5px;
      transition: background-color 0.3s, color 0.3s;
      text-align: center;

      &.active {
        background-color: #2aac94;
        color: white;
      }

      &:hover {
        background-color: #e0f7f3;
        color: #2aac94;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;

    .header-top {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      z-index: 1000;
      height: 3rem;
    }

    .menu-bar {
      position: fixed;
      top: 0; /* 슬라이드 메뉴를 상단에 위치 */
      left: -200px; /* 초기 상태에서 메뉴가 숨겨지도록 */
      height: 100vh;
      width: 200px;
      flex-direction: column;
      justify-content: flex-start;
      background-color: #ffffff;
      transition: transform 0.3s ease-in-out;

      &.open {
        transform: translateX(200px); /* 메뉴가 화면에 나타남 */
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); // 기본 그림자 효과 추가        
      }
      // &.open::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 200px;
      //   width: 100vw;
      //   height: 100%;
      //   background: black;
      //   opacity: 0.5;
      //   z-index: 10;
      // }

      &__nav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 20px; /* 상단 여백 추가 */
        padding-left: 10px; /* 왼쪽 여백 추가 */
        gap: 0.3rem;
        color: #494747;
        font-size: 16px;
        margin-top: 45px;
      }

      &__nav-item {
        text-align: left; /* 텍스트 왼쪽 정렬 */
        padding: 10px 15px;
        width: 80%;
      }
    }

    .menu-overlay {
      content: "";
      position: fixed;
      top: 50px;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: black;
      opacity: 0.5;
      z-index: 0; /* 메뉴 바로 아래에 위치 */
      display: none; /* 초기 상태에서 숨김 */
    }
    
    .menu-overlay.open {
      display: block; /* 메뉴가 열릴 때 보이도록 */
    }
  }
}

.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .menu-icon {
    display: flex;
    z-index: 10000;
    height: 3rem;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 1rem
  }
}
