@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.note-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .note-container {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    height: 100vh;
    background-color: white;

    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    text-align: center;
    opacity: 1;
    transition: opacity 1s ease-out;
    gap: 10px;

  }
  @media (max-width: 768px) {
    .note-container {
      display: flex;
      flex-direction: column;
      max-width: 1200px;
      width: 100%;
      padding: 20px;
      box-shadow: none;
      margin-bottom: 0;
      height: 50vh;
      background-color: white;
      position: absolute;
      top: 110px;
    }
  }
}

@media (max-width: 768px) {
  .note-wrapper {
    min-height: 100vh;
  }
}

.note-grid {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note-buttons {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;

  >button {
    margin: 1px;
    padding: 10px 20px;
    background-color: #2aac94;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 4rem;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:hover {
      background-color: #1e8c74;
    }
  }
  
  @media (max-width: 768px) {
    button {
      padding: 10px 3px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      width: 4rem;
      row-gap: 1px;
      margin: 5px;
    }
  }
}



.note-result-container {
  display: flex;
  position: absolute;
  top: 150px;
  z-index: 1;

  
  .note-result {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    opacity: 0; /* 초기 상태를 투명하게 설정 */

    &.success {
      display: none;

      &.active {
        display: block;
        animation: fadeIn 0.2s ease-in-out;
        opacity: 1; /* 애니메이션이 끝난 후 완전히 보이도록 설정 */
      }
    }

    &.fail {
      display: none;

      &.active {
        display: block;
        animation: fadeIn 0.2s ease-in-out;
        opacity: 1; /* 애니메이션이 끝난 후 완전히 보이도록 설정 */
      }
    }
  }
}

@media (max-width: 768px) {
  .note-result-container {
    display: flex;
    position: absolute;
    top: 40px;
    z-index: 1;
  
    > .note-result {
      animation: fadeIn 0.2s ease-in-out;
      opacity: 1; /* 애니메이션이 끝난 후 완전히 보이도록 설정 */
    }
  }
}