.chord-btn-container {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;

  button {
    margin: 1px;
    padding: 10px 20px;
    background-color: #2aac94;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 10rem;
    height: 40px;

    
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;

    &:hover {
      background-color: #1e8c74;
    }
  }
  
  @media (max-width: 768px) {
    button {
      padding: 10px 3px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      width: 4rem;
      row-gap: 1px;
      margin: 5px;
    }
  }

  >.chord-btn-header {
    padding-bottom: 1rem;
    >button {
      width: 4.5rem;
      background-color: #008080;

      &:hover {
        background-color: #2aa38f;
      }
    }
  }

  @media (max-width: 768px) {
    >.chord-btn-detail {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  >.chord-btn-detail {
    >button {
      width: 5rem;
      background-color: #2aac94;
      white-space: nowrap;

      &:hover {
        background-color: #1e8c74;
      }
    }
  }
}

.note-result-container {
  display: flex;
  position: absolute;
  top: 100px;
  z-index: 1;

  
  .note-result {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    opacity: 0; /* 초기 상태를 투명하게 설정 */

    &.success {
      display: none;

      &.active {
        display: block;
        animation: fadeIn 0.2s ease-in-out;
        opacity: 1; /* 애니메이션이 끝난 후 완전히 보이도록 설정 */
      }
    }

    &.fail {
      display: none;

      &.active {
        display: block;
        animation: fadeIn 0.2s ease-in-out;
        opacity: 1; /* 애니메이션이 끝난 후 완전히 보이도록 설정 */
      }
    }
  }
}

@media (max-width: 768px) {
  .note-result-container {
    display: flex;
    position: absolute;
    top: 40px;
    z-index: 1;
  
    > .note-result {
      animation: fadeIn 0.2s ease-in-out;
      opacity: 1; /* 애니메이션이 끝난 후 완전히 보이도록 설정 */
    }
  }
}

.filter-container {
  position: absolute;
  top: 10%;
  left: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .filter-text {
    color: #666; /* 중간 톤의 회색 */
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: color 0.3s;

    &:hover {
      color: #1e8c74;
    }
  }

  .filter-options {
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .filter-option {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
  }
  
  .filter-option:hover {
    background-color: #f0f0f0;
  }
  
  .filter-option input {
    margin-right: 10px;
    accent-color: #ffae00; /* 체크박스 색상 */
    transform: scale(1.2); /* 체크박스를 조금 키워서 보이기 쉽게 */
  }
  
  .filter-option input:checked + span {
    font-weight: bold;
    color: #ff9d00; /* 체크된 항목의 텍스트 색상 */
  }
}


/* 모바일 환경에 맞춘 스타일 */
@media (max-width: 768px) {
  .filter-container {
    z-index: 100; /* 다른 요소 위에 표시되도록 */
    left: 80%;

    .filter-options {
      width: 250%; /* 필요시 너비 조정 */
      position: absolute; /* 모바일에서 절대 위치 설정 */
      top: 40px; /* 필터 텍스트 아래에 표시 */
      left: -85px;
      right: 0;
      margin: 0 auto; /* 중앙 정렬 */
      z-index: 200; /* 필터 옵션이 가려지지 않도록 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* 그림자 강화 */
    }
  }
}

.ad-container {
  position: fixed;
  width: 300px;
  height: 500px;
  top: 75px;
  left: 43px;
}